<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template
            v-if="$permissionAbility(DELAY_FINE_POLICY_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0 mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->

          <template v-if="props.column.field === 'format_title'">
            <b class="cursor-pointer" v-if="$permissionAbility(DELAY_FINE_POLICY_SHOW, permissions)" v-on:click="onShowPage(props.row)">{{ props?.row?.title }} </b>
            <b v-else>{{ props?.row?.title }} </b>
          </template>

          <template v-if="props.column.field === 'format_attendance'">
            <template v-if="props.row.late_type">
              <b-badge :variant="props.row.late_type === 'delay' ? 'warning' : 'danger'">{{ formatLateType(props.row.late_type) }}</b-badge>

            </template>
          </template>

          <template v-if="props.column.field === 'format_minimum_delay_days'">
            <b-badge class="ml-1" variant="light-success">
              {{ props.row.minimum_delay_days }}
            </b-badge>
          </template>

          <template v-if="props.column.field === 'format_salary_deduction_days'">
            <b-badge class="ml-1" variant="light-primary">
              {{ props.row.salary_deduction_days }}
            </b-badge>
          </template>

          <template v-if="props.column.field === 'format_salary_type'">
            <b-badge :variant="props.row.salary_type == 'basic' ? 'success' : 'primary'">{{ (props.row.salary_type).toUpperCase() }}</b-badge>
          </template>

          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template
                  v-if="$permissionAbility(DELAY_FINE_POLICY_EDIT, permissions)"
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="$permissionAbility(DELAY_FINE_POLICY_SHOW, permissions)"
                >
                  <b-dropdown-item v-on:click="onShowPage(props.row)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Show</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="
                    $permissionAbility(DELAY_FINE_POLICY_DELETE, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-delay-fine-policy-form"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Late Fine Policy'
          : 'Create Late Fine Policy'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >

      <validation-observer ref="addDelayFinePolicy">
        <b-form v-on:submit.prevent="addDelayFinePolicyForm">

          <!-- name -->
          <b-form-group label="Title" label-for="title" class="required-label">
            <validation-provider
                #default="{ errors }"
                name="title"
                vid="title"
                rules="required|max:255"
            >
              <b-form-input
                  id="title"
                  type="text"
                  v-model="title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Policy Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
              label="Late Type"
              label-for="late_type"
              class="required-label"
          >
            <ValidationProvider
                name="Late Type"
                v-slot="{ errors }"
                vid="late_type"
                rules="required"
            >

              <v-select
                  id="late_type"
                  v-model="lateType"
                  :options="lateTypeConstants"
                  :reduce="(option) => option.value"
                  placeholder="Late type"
                  label="name"
                  class="custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>


          <!-- salary_type -->
          <b-form-group
              label="Salary Type "
              label-for="salary_type"
              class="required-label"
          >
            <ValidationProvider
                name="salary type"
                v-slot="{ errors }"
                vid="salary_type"
                rules="required"
            >
              <v-select
                  id="salary_type"
                  v-model="salaryType"
                  :options="salaryTypeConstants"
                  :reduce="(option) => option.value"
                  placeholder="Salary type"
                  label="name"
                  class="custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>




          <b-form-group
            label="For how many days of late"
            label-for="minimum_delay_days"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="minimum delay days"
              vid="minimum_delay_days"
              rules="required|min_value:1"
            >
              <b-form-input
                id="minimum_delay_days"
                type="text"
                v-model="minimumDelayDays"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter Minimum Late Days"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="How many days of salary will be deducted"
            label-for="minimum_delay_days"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="salary deduction days"
              vid="salary_deduction_days"
              rules="required|min_value:1"
            >
              <b-form-input
                id="salary_deduction_days"
                type="text"
                v-model="salaryDeductedDays"
                :state="errors.length > 0 ? false : null"
                placeholder="Salary Deducted Days"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isDelayFinePolicy">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

    <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormDatepicker,
  VBTooltip
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import { salaryTypeConstants } from "@/helpers/constant/salaryTypeConstant";
import { lateTypeConstants } from "@/helpers/constant/lateTypeConstant";
import { amountValueTypeConstants } from "@/helpers/constant/amountValueTypeConstant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  DELAY_FINE_POLICY_CREATE,
  DELAY_FINE_POLICY_EDIT,
  DELAY_FINE_POLICY_DELETE,
  DELAY_FINE_POLICY_SHOW,
} from "@/helpers/permissionsConstant";

export default {
  name: "DelayFinePolicyView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormDatepicker,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      DELAY_FINE_POLICY_CREATE,
      DELAY_FINE_POLICY_EDIT,
      DELAY_FINE_POLICY_DELETE,
      DELAY_FINE_POLICY_SHOW,

      salaryType: "",
      modelType: "",
      salaryTypeConstants,
      lateTypeConstants,

      lateType: "",

      delayFinePolicyId: "",

      AttendanceStatusOptions: [],
      title: "",
      minimumDelayDays: "",
      salaryDeductedDays: "",

      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_attendance",
          sortable: false,
        },
        {
          label: "Minimum Late Days",
          field: "format_minimum_delay_days",
          sortable: false,
        },

        {
          label: "Salary Deducted Days",
          field: "format_salary_deduction_days",
          sortable: false,
        },

        {
          label: "Salary Type",
          field: "format_salary_type",
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
          sortable: true,
          formatFn: this.formatDate,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isDelayFinePolicy: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },
  async created() {
    try {
      const [attendanceStatus] = await Promise.all([
        this.attendanceStatus()
      ])

      this.AttendanceStatusOptions = (attendanceStatus?.data.data || []).map((item) => {
        let name = `${item?.name} (${item?.sort_name})`
        return {
          name,
          id: item?.id
        }
      })

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      })
    }
  },
  methods: {

    async attendanceStatus() {
      return await this.$api.get("api/attendance-status/all?sort_name=D,ED");
    },
    onShowPage(value) {
      let policyCategoryName = "delay-fine-policy";

      let policyCategoryId = value?.policy_category_id;
      let id = value?.id;
      this.$router.push({
        name: "admin-payroll-policy-assign-details",
        params: { policyCategoryName, policyCategoryId, id, type: 'delayFine' },
      });
    },
    onContextPurchaseDate(ctx) {
      this.purchaseDate = ctx.selectedYMD;
    },
    showModal() {
      this.$bvModal.show("modal-delay-fine-policy-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-delay-fine-policy-form");
      this.resetModal();
    },
    resetModal() {
      this.delayFinePolicyId = "";
      this.title = "";

      this.minimumDelayDays = "";
      this.salaryDeductedDays = "";
      this.lateType = "";
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.delayFinePolicyId = value?.id;

      this.title = value?.title;
      this.minimumDelayDays = value?.minimum_delay_days;
      this.salaryDeductedDays = value?.salary_deduction_days;
      this.lateType = value?.late_type;
      this.salaryType = value?.salary_type;

      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/delay-fine-policies/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    formatAmount(row) {
      if (row?.amount_value_type == "percentage") {
        return `${row?.amount_value} %`;
      }
      return row?.amount_value;
    },
    formatAmountType(value) {
      const amountType = amountValueTypeConstants.find(
        (item) => item.value === value
      );
      return amountType?.name;
    },
    formatLateType(value) {
      const lateType = lateTypeConstants.find(
        item => item.value === value,
      );
      return lateType?.name
    },

    formatSalaryType(value) {
      const salaryType = salaryTypeConstants.find(
        (item) => item.value === value
      );
      return salaryType?.name;
    },
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getDelayFinePolicies(params) {
      return await this.$api.get("api/delay-fine-policies", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async getUsers() {
      return await this.$api.get("api/users/all?include=designation");
    },

    async loadItems() {
      try {
        const delayFinePolicies = await this.getDelayFinePolicies({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = delayFinePolicies?.data?.data;
        const meta = delayFinePolicies?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    addDelayFinePolicyForm: async function () {
      this.$refs.addDelayFinePolicy.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isDelayFinePolicy = true;

              await this.$api.put(
                `/api/delay-fine-policies/${this.delayFinePolicyId}`,

                {
                  title: this.title,
                  late_type: this.lateType,
                  minimum_delay_days: this.minimumDelayDays,
                  salary_deduction_days: this.salaryDeductedDays,
                  salary_type: this.salaryType,
                }
              );
              this.isDelayFinePolicy = false;
              this.hiddenModal();
              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Late Fine Policy Successfully Updated",
                },
              });
            } else {
              this.isDelayFinePolicy = true;

              await this.$api.post(
                "/api/delay-fine-policies",

                {
                  title: this.title,
                  late_type: this.lateType,
                  minimum_delay_days: this.minimumDelayDays,
                  salary_deduction_days: this.salaryDeductedDays,
                  salary_type: this.salaryType,
                }
              );
              this.isDelayFinePolicy = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Late Fine Policy Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isDelayFinePolicy = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.addDelayFinePolicy.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
